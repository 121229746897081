import algoliasearch from "algoliasearch/lite"
import React, { useEffect, useState } from "react"
import { SEOSEARCHRESULTS } from "../../components/seo-search-results"
import SearchResultsTemplate from "../../templates/property-results"
import createResultsUrl from "../../utils/property-results/createResultsUrl"

import { usePropertyStore } from "../../components/SearchResults/store/propertyStore"
import useSearchResultsSEO from "../../hooks/useSearchResultsSEO"
import generateFiltersfromPageData from "../../utils/property-results/generateFiltersfromPageData"
import parseResultsUrl from "../../utils/property-results/parseResultsUrl"
import landData from "../../property-data/type_comm_land.json"
import villaData from "../../property-data/type_comm_villa.json"
import factoryData from "../../property-data/type_factory.json"
import fullFloorData from "../../property-data/type_full-floor.json"
import labourData from "../../property-data/type_labour-camp.json"
import officeData from "../../property-data/type_office-space.json"
import retailData from "../../property-data/type_retail.json"
import showRoomData from "../../property-data/type_showroom.json"
import staffData from "../../property-data/type_staff-accommodation.json"
import warehouseData from "../../property-data/type_warehouse.json"
import wholeBuildingData from "../../property-data/type_whole-building.json"
import isbot from "isbot"
import { unzipAndRetrieve, zipAndStore } from "../../components/Common/utils"
import { graphql, useStaticQuery } from "gatsby"
export default function SearchResults(props) {
  const faqData = useStaticQuery(graphql`
    query {
      allStrapiPropertyFaq {
        edges {
          node {
            searchType
            building_type
            department
            faq {
              title
              add {
                title
                content {
                  data {
                    content
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  
  const faqLists = faqData?.allStrapiPropertyFaq?.edges
  const [algoliadata, getAlgoliadata] = useState([])
  const { reset, clearFilters } = usePropertyStore()
  

    const local_storage_data = unzipAndRetrieve("algolia_data_commercial") || []
    const [local_cache, setLocalCache] = useState(local_storage_data)

  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)

  // you can make default search page results filters here
  const myalgoliafilters = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  // navigate to pages based on filters

  const { pageh1, introcopy } = useSearchResultsSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )
  let realuser = true
  useEffect(() => {
    // getAlgoliaResutls(myalgoliafilters)
    if (typeof window !== "undefined") {
      realuser =
        process.env.GATSBY_BlOCK_ALGOLIA_BOT_REQUEST == "true"
          ? isbot(navigator.userAgent)
          : false
    }

    // if (realuser === false) {
    //   getAlgoliaResutls(myalgoliafilters)
    // } else {
    //   // getBotSearchResult()
    // }
    if (
      props?.location?.pathname ===
        "/commercial-properties/for-sale/in-" +
          process.env.GATSBY_DEFAULT_AREA.toLowerCase() +
          "/" ||
      props?.location?.pathname ===
        "/commercial-properties/to-rent/in-" +
          process.env.GATSBY_DEFAULT_AREA.toLowerCase() +
          "/"
    ) {
      reset()
      clearFilters()

    }
  }, [props.location])

  const getFreeText= typeof window!=="undefined"? sessionStorage.getItem("isfreetext"):""

  const getAlgoliaResutls = async (myalgoliafilters,local_cache_filter_data,local_cache_path_name) => {
    // lets run algolia search query to fetch hits, stats and number of pages
    const client = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_API_KEY
    )
    const index = client.initIndex(
      page_url_data["sortVal"]
        ? page_url_data["sortVal"]
        : page_url_data["indexVal"]
    )
    
   await index
      .search((!getFreeText?"":page_url_data['areaVal'] == (process.env.GATSBY_DEFAULT_AREA).toLowerCase() ? '' : page_url_data['areaVal']), {
        // similarQuery: myareaquery != process.env.GATSBY_DEFAULT_AREA ? myareaquery.join(' ') : '',
        filters: myalgoliafilters,
        page: page_url_data["pageVal"] - 1,
        hitsPerPage: page_url_data["layoutVal"] ? 1000 : 8,
      })
      .then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
        var myArray = {
          total: nbHits,
          current_page_number: page,
          hitsPerPage: hitsPerPage,
          number_of_pages: nbPages,
          hits: hits,
          expTime:new Date().setMinutes(new Date().getMinutes() + 5),
          filter_data:local_cache_filter_data
        }
        getAlgoliadata(myArray)
          setLocalCache([...local_cache, { [local_cache_path_name]: myArray }])
      })
  }


   const filterLocalCache = (path_name) => {
      const get_local_cache = local_cache
      let filter_local_cache = []
      if (get_local_cache?.length > 0) {
        const remove_local_cache = get_local_cache?.filter(
          (key) => Object.values(key)[0]?.expTime > new Date().getTime(),
        )
        setLocalCache(remove_local_cache)
  
        filter_local_cache =
          remove_local_cache?.length > 0 &&
          remove_local_cache?.filter((key) => key[path_name])
      }
      return filter_local_cache
    }
  
    useEffect(()=>{
      const path_name = typeof window !== "undefined" ? window.location.href : ""
      let local_cache_path_name = path_name
      let local_cache_filter_data = myalgoliafilters;
     
      const filter_local_cache = filterLocalCache(local_cache_path_name)
      if (
        filter_local_cache?.length > 0 &&
        local_cache_filter_data ===
          filter_local_cache[0][local_cache_path_name].filter_data
      ) {
        const get_data = filter_local_cache[0]
        const get_data_pathname = get_data[local_cache_path_name]
        
        if (get_data_pathname?.total > 0) {
          getAlgoliadata(get_data_pathname)
        } else {
          getAlgoliadata({total:0})
        }
      }
      else if(page_url_data['departmentVal']){
        getAlgoliaResutls(myalgoliafilters,local_cache_filter_data, local_cache_path_name)
      }
  
    },[props.location])
  
    useEffect(() => {
      zipAndStore("algolia_data_commercial",local_cache)
    }, [local_cache])


  const pathName = typeof window !== "undefined" ? window.location.href?.split('/') : ""
  const isPropertyType = page_url_data["buildingval"]!=="" && pathName.length===8;
  const propTypeValue =  page_url_data["buildingval"]
  const searchValType = page_url_data["searchtypeVal"] 
  const faq_data = faqLists?.filter(item=> {
      const list = item?.node
    if(list?.department === page_url_data['departmentVal'] &&
      list?.building_type === propTypeValue&& list?.searchType=== searchValType
    ){
      return item
    }
  })
  const propertySalesData = searchValType === 'sales' && isPropertyType && propTypeValue ==='office-space' ? officeData :
  propTypeValue ==='retail' ? retailData: propTypeValue === "warehouse" ? warehouseData :
  propTypeValue ==='factory'? factoryData : propTypeValue === "commercial-villa"  ? villaData
  : propTypeValue === "showroom"  ? showRoomData : propTypeValue === "full-floor"  ? fullFloorData : propTypeValue === "whole-building"  ? wholeBuildingData
  : propTypeValue === "land"  ? landData : propTypeValue === "labour-camp"  ? labourData : propTypeValue === "staff-accommodation"  ? staffData : ''

  const propertyTypeData = searchValType === 'sales'&& faq_data?.length> 0 ? faq_data[0]?.node?.faq: ''
  const textContent = propertySalesData?.content ? propertySalesData?.content :''


  return (
    <React.Fragment>
      <SearchResultsTemplate
        {...algoliadata}
        pageh1={pageh1}
        introcopy={introcopy}
        page_url_data={page_url_data}
        createResultsUrl={createResultsUrl}
        location_path={props.location.pathname}
        indexname={page_url_data["indexVal"]}
        isCommercial
        getAlgoliadata={getAlgoliadata}
        realuser={typeof window !== "undefined" && isbot(navigator.userAgent)}
        propertyTypeInfo={propertyTypeData}
        content = {textContent}
      />
    </React.Fragment>
  )
}

export const Head = props => {
  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)

  // you can make default search page results filters here
  // const myalgoliafilters = generateFiltersfromPageData(
  //   page_url_data["departmentVal"],
  //   page_url_data["searchtypeVal"],
  //   page_url_data["areaVal"],
  //   page_url_data["bedVal"],
  //   page_url_data["minpriceVal"],
  //   page_url_data["maxpriceVal"],
  //   page_url_data["statusVal"],
  //   page_url_data["buildingval"],
  //   page_url_data["amenities"],
  //   page_url_data["minsqftVal"],
  //   page_url_data["maxsqftVal"],
  //   page_url_data["newdevelopmentsVal"]
  // )

  const { pagetitle, pagemetadesc } = useSearchResultsSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )


  const pathName = typeof window !== "undefined" ? window.location.href?.split('/') : ""
  const isPropertyType = page_url_data["buildingval"]!=="" && pathName.length===8;
  const propTypeValue =  page_url_data["buildingval"]
  const propertySalesData = page_url_data["searchtypeVal"] === 'sales' && isPropertyType && propTypeValue ==='office-space' ? officeData :
  propTypeValue ==='retail' ? retailData: propTypeValue === "warehouse" ? warehouseData :
  propTypeValue ==='factory'? factoryData : propTypeValue === "commercial-villa"  ? villaData
  : propTypeValue === "showroom"  ? showRoomData : propTypeValue === "full-floor"  ? fullFloorData : propTypeValue === "whole-building"  ? wholeBuildingData
  : propTypeValue === "land"  ? landData : propTypeValue === "labour-camp"  ? labourData : propTypeValue === "staff-accommodation"  ? staffData : ''

  const propertyTypeData = propertySalesData ? propertySalesData : ''
  const metaTitle=  isPropertyType ? propertyTypeData?.metaTitle:pagetitle;
  const metaDesc= isPropertyType ? propertyTypeData?.metaDescription:pagemetadesc;

  return (
    <SEOSEARCHRESULTS
      title={`${metaTitle} | ${process.env.GATSBY_SITE_NAME}`}
      description={metaDesc}
      url={props.location.pathname}
    />
  )
}
